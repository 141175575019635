<script lang="ts" setup>
import type { TJob } from '@tl-ui/components/job/JobCard.vue'
import { setErrors, clearErrors } from '@formkit/core'
import type { FormKitFrameworkContext, FormKitGroupValue } from '@formkit/core'
import MarkdownIt from 'markdown-it'
const { $sentryCaptureException, $setGAUser, $setClarityUser, $setFBQUser, $fbqTrack } =
  useNuxtApp()
const { t } = useI18n()
const { loginWithOTP, loginWithPassword, downloadPdfToFile, uploadFileToS3, signup, requestOTP } =
  useAuthFetch()
const viewport = useViewport()
const isMobile = computed(() => viewport.isLessThan('md'))
const { queryUser } = useUserManage()
const { gtag } = useGtag()
const { status: authStatus, data: user, getSession } = useAuth()
const { generateCVReviewForAJobRole } = useJobApplication()
const { getRoleOptions, getYearsOfExperiencesOptions, getEducationLevelOptions } = useFilters()
const roleOptions = getRoleOptions(t, false)
const educationLevelOptions = getEducationLevelOptions(t, false)
const yearsOfExperienceOptions = getYearsOfExperiencesOptions(t, false)

const md = new MarkdownIt()

if (authStatus.value === 'authenticated') {
  await getSession()
}
const props = defineProps({
  show: Boolean,
  signupInviteCode: {
    type: String as PropType<string | undefined>,
    required: false,
    default: undefined
  },
  uiLocation: {
    // For tracking purpose
    type: String as PropType<string>,
    required: true
  }
})

let autoFocusTimeOut: ReturnType<typeof setTimeout>
type stage = 'SELECT_USER' | 'LOGIN_PW' | 'LOGIN_OTP' | 'WITH_AUTH' | 'WITHOUT_AUTH' | 'VIEW_REVIEW'
const currentStage = ref<stage>('SELECT_USER')
const useDefaultResume = ref(true)

const { updateUserProfile, getUserProfile } = useProfile()

const userProfile = ref<Awaited<ReturnType<typeof getUserProfile>> | undefined>()

if (!user.value || !user.value.id_slug) {
  userProfile.value = undefined
} else {
  userProfile.value = await getUserProfile(user.value?.id_slug)
}

const submitBtnText = computed(() => {
  if (currentStage.value === 'SELECT_USER') {
    return t('jobBoard.cvReview.continue')
  }
  if (currentStage.value === 'LOGIN_PW') {
    return t('jobBoard.cvReview.login')
  }
  if (currentStage.value === 'LOGIN_OTP') {
    return t('jobBoard.cvReview.login')
  }
  if (currentStage.value === 'WITHOUT_AUTH') {
    return t('jobBoard.cvReview.signUp')
  }
  return t('jobBoard.cvReview.apply')
})

function autoFocus(fieldName: string) {
  autoFocusTimeOut = setTimeout(() => {
    if (!form.value?.$el) return
    const elm = form.value.$el.querySelector(`input[name="${fieldName}"]`)
    if (elm instanceof HTMLInputElement) {
      elm.focus()
    }
  }, 200)
}

const formId = `resume-review-form`
const otpLoading = ref(false)
const otpCountDown = ref(-1)
const countDownInterval = ref<NodeJS.Timeout | null>(null)
const form = ref<{ $el: HTMLElement } | null>(null)
const formValues = ref<{
  email?: string
  password?: string
  phone?: {
    value: string
    isValid: boolean
  }
  firstName?: string
  lastName?: string
  resume?: File
  jobRole?: string
  educationLevel?: string
  yearsOfExperience?: string
  updateProfileResume?: boolean
}>({
  email: undefined,
  password: undefined,
  firstName: undefined,
  lastName: undefined,
  phone: undefined,
  resume: undefined,
  jobRole: undefined,
  educationLevel: undefined,
  yearsOfExperience: undefined,
  updateProfileResume: false
})
const resumeUploading = ref<boolean>(false)
const resumeReviewLoading = ref<boolean>(false)
const applyStart = ref<boolean>(false)
const aiCVReviewJobRecommendations = ref<TJob[]>([])
const aiCVReviewResult = ref<string | null>('')
const CVFileSlug = ref<string | undefined | null>('')

const parsedMd = computed(() => md.render(aiCVReviewResult.value as string))

function reset() {
  if (authStatus.value === 'authenticated') {
    currentStage.value = 'WITH_AUTH'
  } else {
    currentStage.value = 'SELECT_USER'
  }
}

async function prefillForm() {
  if (user.value) {
    try {
      userProfile.value = await getUserProfile(user.value.id_slug)
      useDefaultResume.value = !!userProfile.value?.resume_for_preview
    } catch (error) {
      console.log('error', error)
    }
  } else {
    useDefaultResume.value = false
  }
  formValues.value = {
    email: user.value ? user.value.email : undefined,
    password: undefined,
    firstName: user.value?.first_name ? user.value.first_name : undefined,
    lastName: user.value?.last_name ? user.value.last_name : undefined,
    phone: user.value
      ? {
          value: user.value.secret_part?.phone || '',
          isValid: true
        }
      : undefined,
    resume: undefined,
    updateProfileResume: false
  }

  if (useDefaultResume.value && userProfile.value?.resume_for_preview && !applyStart.value) {
    // upload default user profile on first load
    await uploadCV()
  } else {
    console.log(useDefaultResume.value, userProfile.value?.resume_for_preview, applyStart.value) // debug only
  }
}
reset()

const emit = defineEmits(['update:show'])
async function handleShow(show: boolean) {
  if (!show) {
    reset()
    if (countDownInterval.value) clearInterval(countDownInterval.value)
    if (autoFocusTimeOut) clearTimeout(autoFocusTimeOut)
  } else {
    console.log('show modal') // debug only
    autoFocus('email')
    reset()
    await prefillForm()
  }
  emit('update:show', show)
}

onUnmounted(() => {
  if (countDownInterval.value) clearInterval(countDownInterval.value)
  if (autoFocusTimeOut) clearTimeout(autoFocusTimeOut)
})

async function handleRequestOTP() {
  currentStage.value = 'LOGIN_OTP'
  if (!formValues.value.email) return
  try {
    otpLoading.value = true
    otpCountDown.value = 60
    await requestOTP(formValues.value.email)
    if (countDownInterval.value) clearInterval(countDownInterval.value)
    otpLoading.value = false
    countDownInterval.value = setInterval(() => {
      otpCountDown.value--
      if (otpCountDown.value <= 0 && countDownInterval.value) {
        otpCountDown.value = -1
        clearInterval(countDownInterval.value)
      }
    }, 1000)
    autoFocus('password')
  } catch (error) {
    otpLoading.value = false
    otpCountDown.value = -1
    setErrors(formId, ['something-went-wrong'])
    $sentryCaptureException(error)
  }
}

async function handleSearchUser() {
  if (!formValues.value.email) return
  resetError()
  try {
    const res = await queryUser(formValues.value.email)
    formValues.value = {
      ...formValues.value,
      password: undefined,
      firstName: undefined,
      lastName: undefined,
      phone: undefined,
      resume: undefined
    }
    if (res.exist) {
      // If the email is already registered, we will ask for password/login
      currentStage.value = 'LOGIN_PW'
      autoFocus('password')
    } else {
      // If the email is not registered, we will ask for name, phone, resume for application directly without login
      currentStage.value = 'WITHOUT_AUTH'
      autoFocus('firstName')
    }
  } catch (error) {
    console.error(error)
    handleFetchError(error, {
      handleUnknownError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      },
      handle401Error: () => {
        setErrors(formId, ['invalid-email-or-password'])
      },
      handle429Error: () => {
        setErrors(formId, ['too-many-requests'])
      },
      handle500OrAboveError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      }
    })
  }
}

async function handleOTPLogin() {
  resetError()
  if (!formValues.value.email || !formValues.value.password) return
  try {
    const response = await loginWithOTP(formValues.value.email, formValues.value.password)
    const { rawToken } = useAuthState()
    rawToken.value = response.access

    await nextTick(getSession)
    const { data: user } = useAuth()
    formValues.value.phone = {
      value: user.value?.secret_part?.phone || '',
      isValid: true
    }
    // emit GA login event
    $setGAUser(user.value)
    $setClarityUser(user.value)
    $setFBQUser(user.value)
    gtag('event', 'login', {
      method: 'OTP',
      location: 'Job Application Modal',
      account_type: getPrimaryUserRole(user.value)
    })

    currentStage.value = 'WITH_AUTH'
    autoFocus('firstName')
    await prefillForm()
  } catch (error) {
    handleFetchError(error, {
      handleUnknownError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      },
      handle401Error: () => {
        setErrors(formId, ['invalid-email-or-password'])
      },
      handle403Error: () => {
        setErrors(formId, ['invalid-email-or-password'])
      },
      handle500OrAboveError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      }
    })
  }
}

async function handleLogin() {
  resetError()
  if (!formValues.value.email || !formValues.value.password) return
  try {
    const response = await loginWithPassword(formValues.value.email, formValues.value.password)
    const { rawToken } = useAuthState()
    rawToken.value = response.access

    await nextTick(getSession)
    const { data: user } = useAuth()
    formValues.value.phone = {
      value: user.value?.secret_part?.phone || '',
      isValid: true
    }
    // emit GA login event
    $setGAUser(user.value)
    $setClarityUser(user.value)
    $setFBQUser(user.value)
    gtag('event', 'login', {
      method: 'Password',
      location: 'Job Application Modal',
      account_type: getPrimaryUserRole(user.value)
    })

    currentStage.value = 'WITH_AUTH'
    await prefillForm()
  } catch (error) {
    handleFetchError(error, {
      handleUnknownError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      },
      handle401Error: () => {
        setErrors(formId, ['invalid-email-or-password'])
      },
      handle500OrAboveError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      }
    })
  }
}

async function handleSignup() {
  resetError()
  if (!formValues.value.email || !formValues.value.firstName || !formValues.value.lastName) return

  // Create a user account with random password
  // Signup
  try {
    const password = generatePassword()
    await signup({
      email: formValues.value.email,
      password,
      first_name: formValues.value.firstName,
      last_name: formValues.value.lastName,
      phone: formValues.value.phone?.value,
      user_roles: ['STUDENT'],
      anonymous_signup_stage: 'SELF_EXPRESS_SIGNUP',
      invite_code: props.signupInviteCode || undefined
    })

    // Login
    const response = await loginWithPassword(formValues.value.email, password)
    const { rawToken } = useAuthState()
    rawToken.value = response.access
    await nextTick(getSession)
    const { data: user } = useAuth()
    // emit GA login event
    $setGAUser(user.value)
    $setClarityUser(user.value)
    $setFBQUser(user.value)

    gtag('event', 'sign_up', {
      method: 'Password',
      lead_source: 'Self signup',
      location: props.uiLocation,
      account_type: 'candidate',
      invite_code: props.signupInviteCode || undefined
    })
    gtag('event', 'login', {
      method: 'Password',
      location: props.uiLocation,
      account_type: 'candidate'
    })

    $fbqTrack('CompleteRegistration', {
      content_name: props.uiLocation,
      content_category: 'ResumeReviewModal',
      delivery_category: 'ResumeReviewModal'
    })

    currentStage.value = 'WITH_AUTH'
  } catch (error) {
    setErrors(formId, ['something-went-wrong'])
    $sentryCaptureException(error)
  }
}

async function handleCVReviewAndJobRecommendation() {
  resetError()
  if (!formValues.value.resume || !formValues.value.jobRole) {
    console.log('Invalid input', formValues.value) // debug only
    return
  }
  if (!user.value) {
    console.log('User not found') // debug only
    return
  }
  if (!CVFileSlug.value) {
    alert('Failed to apply CV review, please try again later.')
    return
  }

  applyStart.value = true
  resumeReviewLoading.value = true
  console.log('Apply start') // debug only

  userProfile.value = await getUserProfile(user.value.id_slug)

  let fileIdSlug: string | undefined | null = ''
  if (formValues.value.updateProfileResume) {
    console.log('Update profile resume') // debug only
    // Upload file to S3 for default resume
    try {
      fileIdSlug = await uploadFileToS3('/api/profile-files/', formValues.value.resume, {
        file_type: 'RESUME'
      })
    } catch (error) {
      handleFetchError(error, {
        handleUnknownError: () => {
          setErrors(formId, ['something-went-wrong'])
          $sentryCaptureException(error)
        },
        handle400Error: () => {
          setErrors(formId, ['invalid-input'])
        },
        handle500OrAboveError: () => {
          setErrors(formId, ['something-went-wrong'])
          $sentryCaptureException(error)
        }
      })
      resumeReviewLoading.value = false
      return
    }
    try {
      await updateUserProfile(user.value.id_slug, {
        resume_id_slug: fileIdSlug
      })
    } catch (error) {
      setErrors(formId, ['something-went-wrong'])
      $sentryCaptureException(error)
      resumeReviewLoading.value = false
      return
    }
  }

  // obtain the cv review results and the job recommendation
  try {
    gtag('event', 'request_cv_review', {
      job_role: formValues.value.jobRole,
      education_level: formValues.value.educationLevel,
      years_of_experience: formValues.value.yearsOfExperience,
      location: props.uiLocation
    })
    $fbqTrack('Lead', {
      content_name: props.uiLocation,
      content_category: 'ResumeReviewModal',
      delivery_category: 'ResumeReviewModal'
    })
    const result = await generateCVReviewForAJobRole({
      job_role: formValues.value.jobRole,
      years_of_experience: formValues.value.yearsOfExperience,
      education_level: formValues.value.educationLevel,
      relevancy_test_file_id_slug: CVFileSlug.value
    })
    aiCVReviewResult.value = result.recommendation
    aiCVReviewJobRecommendations.value = result.recommended_jobs.map((job) => NAPIToTJob(t, job))
    currentStage.value = 'VIEW_REVIEW'
  } catch (error) {
    handleFetchError(error, {
      handleUnknownError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      },
      handle400Error: (_error) => {
        setErrors(formId, [_error.response?._data.job || 'invalid-input'])
      },
      handle500OrAboveError: () => {
        setErrors(formId, ['something-went-wrong'])
        $sentryCaptureException(error)
      }
    })
  } finally {
    resumeReviewLoading.value = false
    applyStart.value = false
  }
}

async function handleSubmit() {
  console.log('handleSubmit', currentStage.value)
  if (currentStage.value === 'SELECT_USER') {
    return await handleSearchUser()
  }
  if (currentStage.value === 'LOGIN_PW') {
    return await handleLogin()
  }
  if (currentStage.value === 'LOGIN_OTP') {
    return await handleOTPLogin()
  }
  if (currentStage.value === 'WITHOUT_AUTH') {
    return await handleSignup()
  }
  if (currentStage.value === 'WITH_AUTH') {
    return await handleCVReviewAndJobRecommendation()
  }
}

function resetError() {
  clearErrors(formId)
}

async function uploadCV() {
  if (!user.value?.id_slug) return
  resumeUploading.value = true
  // this will trigger re-render even if userProfile is not updated
  userProfile.value = await getUserProfile(user.value?.id_slug)

  let fileIdSlug: string | undefined | null = ''
  if (useDefaultResume.value) {
    if (userProfile.value?.resume?.presigned_get_object_url) {
      formValues.value.resume = await downloadPdfToFile(
        userProfile.value?.resume.presigned_get_object_url,
        `${userProfile.value?.about?.first_name}_${userProfile.value?.about?.last_name}.pdf`
      )
      console.log(formValues.value) // debug only
    }
  }
  console.log('uploadCV', formValues.value.resume) // debug only

  if (!formValues.value.resume) {
    resumeUploading.value = false
    await uploadCV()
    return
  }

  try {
    fileIdSlug = await uploadFileToS3('/napis/v1/cv-review/temp-file/', formValues.value.resume, {
      file_type: 'RESUME'
    })
    console.log('fileIdSlug', fileIdSlug, 'upload to S3') // debug only
    CVFileSlug.value = fileIdSlug
    resumeUploading.value = false
  } catch (error) {
    handleFetchError(error, {
      handleUnknownError: () => {
        $sentryCaptureException(error)
      },
      handle400Error: () => {},
      handle500OrAboveError: () => {
        $sentryCaptureException(error)
      }
    })
    resumeUploading.value = false
  }

  if (!useDefaultResume.value) {
    formValues.value.updateProfileResume = true
  }
}

async function handleFileInput(event: any) {
  if (!event) return
  await uploadCV()
}

function handleClickUseNewResume() {
  useDefaultResume.value = false
  formValues.value.resume = undefined
  formValues.value.updateProfileResume = true
}

async function handleClickUseDefaultResume() {
  useDefaultResume.value = true
  formValues.value.resume = undefined
  formValues.value.updateProfileResume = false
  await uploadCV()
}

const isUserPrimaryCandidate = computed(() => {
  const { data: user } = useAuth()
  return isPrimaryCandidate(user.value)
})
</script>

<template>
  <Modal
    :show="props.show"
    :width-override="isMobile ? undefined : '55rem'"
    :close-on-overlay-click="false"
    @update:show="handleShow"
  >
    <div class="max-h-[80vh] overflow-y-auto px-[1.88rem] pt-0 md:px-[3.12rem]">
      <div class="w-full">
        <SvgCVReviewBanner
          v-if="currentStage !== 'VIEW_REVIEW'"
          :is-mobile="isMobile"
          :main-text="$t('jobBoard.cvReview.bannerTextBeforeReview')"
        />
        <SvgCVReviewBanner
          v-else
          :is-mobile="isMobile"
          :main-text="$t('jobBoard.cvReview.bannerTextAfterReview')"
        />
      </div>
      <FormKit
        :id="formId"
        ref="form"
        v-model="formValues"
        type="form"
        :actions="false"
        @update:model-value="resetError"
        @submit="handleSubmit"
      >
        <div class="flex flex-col gap-[1.88rem] pt-[1.88rem]">
          <FormKitInput
            v-if="currentStage !== 'VIEW_REVIEW' && currentStage !== 'WITH_AUTH'"
            type="email"
            name="email"
            autocomplete="email,username"
            :disabled="currentStage !== 'SELECT_USER'"
            :label="$t('common.auth.email')"
            :placeholder="$t('common.auth.email')"
            validation="required|email"
            validation-visibility="dirty"
          />
          <div v-if="currentStage === 'LOGIN_PW'" class="flex flex-col gap-[0.88rem]">
            <FormKitInput
              type="password"
              name="password"
              :label="$t('common.auth.password')"
              :placeholder="$t('common.auth.password')"
              validation="required|length:6"
              validation-visibility="dirty"
              autocomplete="current-password"
              @keyup.enter="$formkit.submit(formId)"
            />
            <div class="flex flex-row justify-start font-light">
              <div>
                {{ $t('common.auth.forgotPassword') }}
                <a
                  type="button"
                  class="cursor-pointer text-blue-40 hover:underline"
                  @click="
                    () => {
                      currentStage = 'LOGIN_OTP'
                      autoFocus('password')
                    }
                  "
                >
                  {{ $t('common.auth.loginWithOTP') }}
                </a>
              </div>
            </div>
          </div>

          <div v-if="currentStage === 'LOGIN_OTP'" class="flex flex-col gap-[0.88rem]">
            <div class="flex flex-row gap-[0.88rem]">
              <FormKitInput
                type="password"
                name="password"
                :label="$t('common.auth.OTP')"
                :placeholder="$t('common.auth.OTP')"
                validation="required"
                validation-visibility="dirty"
                @keyup.enter="$formkit.submit(formId)"
              />
              <div class="mt-[40px]">
                <ButtonBlackButton
                  :text="otpCountDown > 0 ? `${otpCountDown}` : $t('common.form.send')"
                  :loading="otpLoading || otpCountDown > 0"
                  :height-override="'2.75rem'"
                  @click="handleRequestOTP"
                />
              </div>
            </div>
          </div>
          <template v-if="currentStage === 'WITHOUT_AUTH'">
            <div class="flex flex-col gap-[1.88rem] md:flex-row md:gap-[0.88rem]">
              <FormKitInput
                type="text"
                name="firstName"
                :label="$t('common.auth.firstName')"
                :placeholder="$t('common.auth.firstName')"
                validation="required"
                validation-visibility="dirty"
              />
              <FormKitInput
                type="text"
                name="lastName"
                :label="$t('common.auth.lastName')"
                :placeholder="$t('common.auth.lastName')"
                validation="required"
                validation-visibility="dirty"
              />
            </div>
            <FormKitInput
              type="tlPhone"
              name="phone"
              :label="$t('common.auth.phone')"
              validation="required|requiredphone|tlphone"
              validation-visibility="dirty"
            />
          </template>
          <div v-if="currentStage === 'WITH_AUTH'" class="flex flex-col gap-[0.88rem]">
            <FormKitInput
              type="tlSelectV2"
              name="jobRole"
              :label="$t('jobBoard.filter.role.cvReviewLabel')"
              :placeholder="$t('jobBoard.filter.role.cvReviewPlaceholder')"
              :item-options="roleOptions"
              validation="required"
              validation-visibility="dirty"
            />
            <FormKitInput
              type="tlSelectV2"
              name="educationLevel"
              :label="$t('jobBoard.filter.educationLevel.label')"
              :placeholder="$t('jobBoard.filter.educationLevel.placeholder')"
              :item-options="educationLevelOptions"
              validation="required"
              validation-visibility="dirty"
            />
            <FormKitInput
              type="tlSelectV2"
              name="yearsOfExperience"
              :label="$t('jobBoard.filter.yearsOfExperience.label')"
              :placeholder="$t('jobBoard.filter.yearsOfExperience.placeholder')"
              :item-options="yearsOfExperienceOptions"
              validation="required"
              validation-visibility="dirty"
            />
            <div class="text-t5 font-medium">
              {{ $t('jobBoard.cvReview.resume') }}
              <span class="text-red-40">*</span>
            </div>

            <ProgressBar
              v-if="resumeUploading"
              :variant="'infinite'"
              :color="'purple'"
              :label-text="$t('jobBoard.cvReview.label_text_uploading')"
              :show-sub-label-text="true"
              :show-info-icon="true"
              :sub-label-text="$t('jobBoard.cvReview.subLabel_text')"
              :tooltip-text="$t('jobBoard.cvReview.tooltip_text_uploading')"
            />

            <PDFViewer
              v-if="
                useDefaultResume &&
                userProfile &&
                userProfile.resume_for_preview?.presigned_get_object_url
              "
              :url="userProfile.resume_for_preview.presigned_get_object_url"
              height="300px"
              width="100%"
              :download-text="$t('jobBoard.profilePage.checkMyProfileResume')"
            />
            <FormKitInput
              v-else
              type="tlFile"
              label=""
              name="resume"
              validation="required"
              validation-visibility="dirty"
              accept=".pdf"
              :upload-text="$t('jobBoard.profilePage.uploadResumeHere')"
              :disabled="resumeUploading || resumeReviewLoading"
              autofocus
              @input="handleFileInput"
            />

            <!-- disabled the anchor tag when resume is uploading to prevent cancel upload -->
            <a
              v-if="useDefaultResume && userProfile && userProfile.resume_for_preview"
              type="button"
              class="text-blue-40"
              :class="
                resumeUploading || resumeReviewLoading
                  ? 'pointer-events-none opacity-50'
                  : 'pointer-events-auto cursor-pointer hover:underline'
              "
              @click="handleClickUseNewResume"
            >
              {{ $t('jobBoard.jobPage.useNewResume') }}
            </a>
            <a
              v-else-if="userProfile && userProfile.resume_for_preview"
              type="button"
              class="text-blue-40"
              :class="
                resumeUploading || resumeReviewLoading
                  ? 'pointer-events-none opacity-50'
                  : 'pointer-events-auto cursor-pointer hover:underline'
              "
              @click="handleClickUseDefaultResume"
            >
              {{ $t('jobBoard.jobPage.useDefaultResume') }}
            </a>
            <FormKitInput
              v-if="formValues.resume && !useDefaultResume"
              type="checkbox"
              name="updateProfileResume"
              :disabled="resumeUploading || resumeReviewLoading || props.signupInviteCode"
              :label="$t('jobBoard.jobPage.saveResumeForLaterApplication')"
            />

            <ProgressBar
              v-if="resumeReviewLoading"
              :variant="'infinite'"
              :color="'green'"
              :label-text="$t('jobBoard.cvReview.label_text_reviewing')"
              :show-sub-label-text="true"
              :show-info-icon="true"
              :sub-label-text="$t('jobBoard.cvReview.subLabel_text')"
              :tooltip-text="$t('jobBoard.cvReview.tooltip_text_reviewing')"
            />
          </div>
          <div v-if="currentStage === 'VIEW_REVIEW'" class="flex flex-col gap-[0.88rem]">
            <!-- <div class="text-xs flex justify-center font-light">
              {{ $t('jobBoard.cvReview.sendEmail') }}
            </div> -->
            <div class="text-t5 font-medium">
              {{ $t('jobBoard.cvReview.reviewResults') }}
            </div>
            <div v-if="aiCVReviewResult" class="ai-review-content" v-html="parsedMd"></div>

            <div v-if="aiCVReviewJobRecommendations.length > 0" class="text-t5 font-medium">
              {{ $t('jobBoard.cvReview.jobRecommendationResults') }}
            </div>
            <div v-if="aiCVReviewJobRecommendations.length > 0" class="flex flex-col gap-[1.25rem]">
              <JobCard
                v-for="job in aiCVReviewJobRecommendations"
                :key="job.idSlug"
                :job="job"
                :redirect="true"
              >
                <template v-if="job?.logo?.url" #company-logo>
                  <JobCardLogo :logo="job.logo" :company="job.company" />
                </template>
                <template v-if="isUserPrimaryCandidate" #save-button>
                  <JobButtonSave
                    :job="job"
                    :variant="job.saved ? 'normal' : 'outlined'"
                    size="small"
                    :ui-location="props.uiLocation + ' ResumeReviewModal'"
                  />
                </template>
              </JobCard>
            </div>
          </div>
        </div>
        <template #messages="context: FormKitFrameworkContext<FormKitGroupValue | undefined>">
          <div
            class="mt-[1.25rem] flex flex-row items-center gap-[1.25rem] rounded-card text-t6 font-light text-red-40 transition-[height] duration-150"
            :class="{
              'h-0 overflow-hidden p-0': errorMsgObjToArr($t, context.messages).length === 0,
              'ring-0': errorMsgObjToArr($t, context.messages).length > 0
            }"
          >
            <ul>
              <li v-for="(message, idx) in errorMsgObjToArr($t, context.messages)" :key="idx">
                {{ message }}
              </li>
            </ul>
          </div></template
        >
        <template #actions="context">
          <div v-if="currentStage !== 'VIEW_REVIEW'" class="mt-[1.25rem] flex flex-row justify-end">
            <ButtonBlackButton
              :loading="Boolean(context?.node?.store.loading?.value)"
              :text="submitBtnText"
              :disabled="resumeUploading || resumeReviewLoading"
              @click="$formkit.submit(formId)"
            ></ButtonBlackButton>
          </div>
          <div
            v-if="currentStage === 'VIEW_REVIEW'"
            class="mt-[1.25rem] flex flex-row justify-center"
          >
            <ButtonBlackButton
              mode="windowOpen"
              :text="$t('jobBoard.cvReview.findDreamJob')"
              to="/jobs"
            ></ButtonBlackButton>
          </div>
        </template>
      </FormKit>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
:deep(.ai-review-content) {
  h4 {
    @apply text-h6 font-medium;
  }
  h3 {
    @apply text-h5 font-medium;
  }
  h2 {
    @apply text-h4 font-medium;
  }
  h1 {
    @apply text-h3 font-medium;
  }
  p:first-child {
    @apply mb-[1.25rem] text-t5 font-light;
  }
  p:not(:first-child) {
    @apply my-[1.25rem] text-t5 font-light;
  }
  img {
    @apply mx-auto my-[1.25rem] w-full;
  }
  strong {
    @apply text-t5 font-medium;
  }
  ol {
    @apply my-[1.25rem] list-inside list-decimal;
  }
  ul {
    @apply my-[1.25rem] list-inside list-disc;
  }
  pre {
    @apply my-[1.25rem] text-pretty rounded-md bg-gray-30 p-[1.25rem];
  }
  li {
    p {
      display: inline;
    }
  }
}
</style>
